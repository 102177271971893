// themes - our custom or/and out of the box themes
@import './@theme/styles/themes.scss';

@include nb-install {

  //create dialog style
  nb-user-dialog nb-card {
    margin-bottom: 0 !important;
    border       : none !important;
    width        : 70rem;
  }

  nb-card-header {
    font-size: 1.1rem;
  }

  .image-card {
    background-color: nb-theme(color-primary-400) !important;
  }

  .dialog-separator {
    border-right: 1px solid nb-theme(color-basic-900) !important;
  }

  .section-title {
    margin-bottom: .8rem !important;
  }

  .input-key {
    margin-bottom: .5rem !important;
    display      : block !important;
  }

  .create-dialog input {
    max-width    : none !important;
    width        : 100% !important;
    margin-bottom: .5rem !important;
  }

  .create-dialog nb-select {
    max-width    : none !important;
    width        : 100% !important;
    margin-bottom: .5rem !important;
  }

  .create-dialog [nbButton] {
    max-width    : none !important;
    width        : 100% !important;
    margin-bottom: .5rem !important;
  }

  .image-container {
    display           : flex;
    flex-direction    : column;
    justify-content   : center;
    // margin         : 4px 0;
    margin-bottom     : -12px;
    padding-left      : 0px !important;
  }

  .personal-image {
    width          : 5rem;
    height         : 5rem;
    border-radius  : 50%;
    border         : 2px dashed #B4B4DB;
    padding        : 0.2rem;
    cursor         : pointer;
    object-fit     : cover;
    object-position: top;
  }

  .avatar-image {
    width          : 5rem;
    height         : 5rem;
    border-radius  : 50%;
    border         : 2px dashed #B4B4DB;
    padding        : 0.5rem;
    cursor         : pointer;
    object-fit     : cover;
    object-position: top;
  }

  .img-hidden {
    display: none !important;
  }

  .img-shown {
    display: block !important;
  }

  .radio-button {
    display           : flex;
    flex-direction    : row;
    justify-content   : space-around;
    // background     : nb-theme(color-basic-900);
    border            : 1px solid nb-theme(color-basic-900);
    border-radius     : 0.25rem;
    padding           : 2px;
  }

  .radio-button .outer-circle {
    box-shadow: none !important;
  }

  .radio-button label {
    // padding-right: 1rem !important;
    padding-right: 0rem !important;
  }

  .create-dialog .submit {
    float      : right;
    margin-left: 1rem;
    width      : auto !important;
  }

  .create-dialog .cancel {
    width: auto !important;
  }

  //delete dialog style
  .delete-dialog {
    width: 30rem !important;
  }

  .delete-dialog nb-card-body {
    padding: 2rem 1.5rem 1rem 1.5rem;
  }

  .delete-icon {
    font-size: 6rem !important;
    color    : nb-theme(color-danger-500) !important;
    display  : block !important;
    margin   : 0 auto !important;
  }

  .delete-title {
    color     : nb-theme(color-danger-500) !important;
    text-align: center !important;
  }

  .delete-content {
    display      : block;
    text-align   : center !important;
    margin-bottom: 2rem !important;
  }

  .delete-tag {
    font-weight: bold;
  }

  .delete-dialog .submit {
    text-transform  : capitalize !important;
    width           : 100% !important;
    background-color: nb-theme(color-danger-500) !important;
    border-color    : nb-theme(color-danger-500) !important;
  }

  .delete-dialog .cancel {
    background    : nb-theme(color-basic-900) !important;
    text-transform: capitalize !important;
    border-color  : nb-theme(color-basic-900) !important;
    width         : 100% !important;
  }

  .validation {
    color           : nb-theme(color-danger-500) !important;
    // margin-bottom: 10px;
  }
}
