@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "~@nebular/theme/styles/globals";
@import "~@nebular/auth/styles/globals";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  .container {
    margin: 0;
    min-width: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: nb-theme(background-basic-color-1);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: nb-theme(text-hint-color);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
  }

  * {
    scroll-behavior: smooth;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

};
