//scrollbar styles (width and corners and its colors and back colors)

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
}

* {
    scrollbar-width: thin;
    scrollbar-color: blue yellow;
}

/* Works on Chrome, Edge, and Safari */
//scroll width
*::-webkit-scrollbar {
    width : 6px;
    height: 6px;
}

//scrollbar background
*::-webkit-scrollbar-track {
    background  : black;
    //background: var(--scroll-bar-bg-color);
}

//scrollbar scroll color and border radius
*::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius   : 20px;
    border          : 3px solid white;
}