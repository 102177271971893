// themes - our custom or/and out of the box themes
@import './@theme/styles/themes.scss';

@include nb-install {
  .hand {
    cursor: pointer;
  }

  .action-panel {
    margin-bottom  : 0.8rem;
    display        : flex;
    justify-content: space-between;
  }

  .action-panel .page-title {
    display: block;
    margin : auto 0 !important;
  }

  .action-panel .page-title h4 {
    margin-bottom: 0 !important;
  }

  .action-panel .action-icon-btn {
    margin-left     : .5rem;
    background-color: nb-theme(color-basic-800) !important;
    border-color    : nb-theme(color-basic-800) !important;
    box-shadow      : none !important;
  }

  .action-panel .action-btn {
    margin-left   : .5rem;
    box-shadow    : none !important;
    text-transform: none !important;
  }

  // Tabs
  .action-panel .tab-buttons {
    width         : 6rem;
    box-shadow    : none !important;
    text-transform: none !important;
    box-shadow    : none !important;
  }

  .left-button {
    border-bottom-left-radius : 30px !important;
    border-top-left-radius    : 30px !important;
    border-right              : 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius   : 0 !important;
  }

  .center-button {
    border-left  : 0 !important;
    border-right : 0 !important;
    border-radius: 0 !important;
  }

  .right-button {
    border-bottom-right-radius: 30px !important;
    border-top-right-radius   : 30px !important;
    border-left               : 0 !important;
    border-bottom-left-radius : 0 !important;
    border-top-left-radius    : 0 !important;
  }

  .action-panel .active {
    background: nb-theme(color-primary-500) !important;
    color     : nb-theme(color-basic-100) !important;
  }

  .btn-txt {
    margin-left: 4px;
  }

  .table-card {
    min-height: 522px;
  }

  // table scrollable
  .table-container {
    // height       : 540px;
    // min-height      : 558px;
    min-height      : 66.4vh;
    // height       : 70.7vh;
    overflow-y      : auto;
    border-radius   : 0.25rem;
  }

  // ng2-smart-table table tbody {
  //   height: 506px;
  // }

  ng2-smart-table {
    display           : flex;
    flex-flow         : column nowrap;
    justify-content   : space-between;
    // height         : 100%;
    min-height        : 66vh;
  }

  //center table cells and table headers
  ng2-smart-table table tr td,
  ng2-smart-table table tr th {
    text-align: center;
    padding   : .712rem 1.25rem !important;
  }

  ng2-smart-table table tr th {
    border          : none !important;
    border-bottom   : 1px solid nb-theme(color-basic-1000) !important;
    // padding      : 1rem 1.25rem !important;
  }

  ng2-smart-table table tr td {
    border: none !important;
  }

  ng2-smart-table table tr:last-child {
    border-bottom: .4px solid nb-theme(color-basic-1000) !important;
  }



  .tag {
    color        : nb-theme(color-basic-100);
    background   : nb-theme(color-primary-500);
    padding      : 5px 17px;
    border-radius: 20px;
  }

  .outer-tag {
    padding      : 5px 17px;
    border       : 1px solid nb-theme(color-primary-500);
    border-radius: 20px;
  }

  // .check-icon {
  // color: nb-theme(color-success-500);
  // }

  //Pagination
  nav.ng2-smart-pagination-nav {
    //margin-top: 1.25rem;
    margin-top : 0rem !important;
    padding-top: 1.1rem;
    border-top : 1px solid nb-theme(color-basic-1000);
  }
}

.table-icon {
  width: 30px;
}

nb-layout-column {
  padding: 1.25rem 2.25rem 0.75rem !important;
}

// color of table filters inputs
.ng2-smart-filter input {
  color: black !important;
}
